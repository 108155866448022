// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apartmany-1-js": () => import("./../../../src/pages/apartmany/1.js" /* webpackChunkName: "component---src-pages-apartmany-1-js" */),
  "component---src-pages-apartmany-3-js": () => import("./../../../src/pages/apartmany/3.js" /* webpackChunkName: "component---src-pages-apartmany-3-js" */),
  "component---src-pages-apartmany-4-js": () => import("./../../../src/pages/apartmany/4.js" /* webpackChunkName: "component---src-pages-apartmany-4-js" */),
  "component---src-pages-apartmany-5-js": () => import("./../../../src/pages/apartmany/5.js" /* webpackChunkName: "component---src-pages-apartmany-5-js" */),
  "component---src-pages-apartmany-js": () => import("./../../../src/pages/apartmany.js" /* webpackChunkName: "component---src-pages-apartmany-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-gastro-js": () => import("./../../../src/pages/gastro.js" /* webpackChunkName: "component---src-pages-gastro-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */)
}

